<template>
<div class="header">
    <ui-row>
        <ui-col half>
            <h1 @click="$router.push('/')" class="logo">DiggDawg</h1>
        </ui-col>

        <ui-col half>
            <nav class="top-navigation">
                <router-link v-if="!isAuthenticated" to="/login">Register &amp; Login</router-link>
                <router-link v-else to="/account/details">My Account</router-link>
                <a target="_blank" href="https://www.diggdawg.com/blog/">Blog</a>
            </nav>
        </ui-col>
    </ui-row>
</div>
</template>

<script>
export default {
    computed: {
		user() {
			return this.$store.getters.user
		},

        isAuthenticated() {
            return this.$store.getters.getterIsAuthenticated
        }
	},
}
</script>

<style lang="scss" scoped>
.header {
    display: block;
    top: 0;
    width: 100%;
    background-color: $primary;
    height: 150px;

    @media(max-width: $mobile-bp) {
        height: 80px;
    }

    &__wrapper {
        max-width: 1200px;
        margin: 0px auto;
    }

    .logo {
        color: $white;
        font-size: rem(56);

        @media(max-width: $mobile-bp) {
            font-size: rem(32)
        }
    }

    .top-navigation {
        color: $white;
        padding-top: rem(5);
        text-align: right;

        a.router-link-exact-active {
            color: $secondary;
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 18px;

            & + a {
                margin-left: rem(20)
            }

            &:hover {
               color: $secondary;
            }
        }

        @media(max-width: $mobile-bp) {
            display: none;
        }
    }
}
</style>